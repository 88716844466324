.row {
  display: flex;
}

.w-25 {
  width: 25%;
}

.w-23 {
  width: 23%;
}

.w-50 {
  width: 50%;
}

.padding {
  padding: 0px 20px;
}

.sub-title {
  padding: 0px 0px 5px 20px;
  font-weight: 500;
  font-family: 'Ambit';
}

.existing-questions {
  tr:first-of-type {
    font-size: 16px;
    font-weight: 700;
  }
}
.questions-table {
  table-layout: auto;
  width: 100%;
  border-spacing: 0px;
  tbody {
    text-align: left;
    tr {
      padding-top: 5px;
      td:first-of-type {
        width: 1px;
      }
      td:nth-of-type(2) {
        padding-left: 20px;
      }
      td {
        padding: 10px 0px;
        border-bottom: 1px solid #909090;
        p {
          margin: 0px 0px 5px 0px;
          font-weight: 500;
        }
      }
    }
  }
}

.clone-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #000000;
  svg {
    cursor: pointer;
  }
}
.q-tab {
  font-family: 'Ambit' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 13px !important;
  color: #4e4f50 !important;
  text-transform: capitalize !important;
  &.Mui-selected {
    color: #a72037 !important;
  }
  &:first-child {
    padding-left: 0 !important;
  }
}

.sub-tab {
  min-height: 30px !important;
}

.sub-tabs {
  min-height: 30px !important;
}

.MuiTabs-indicator {
  background-color: #a72037 !important;
}
.q-label {
  color: #2e2f2f !important;
  font-family: 'Ambit' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  margin-bottom: 10px;
}
.q-text-field {
  border: 1px solid #d9d9d9 !important;
  background: #ffffff;
  border-radius: 3px !important;
  color: #2e2f2f !important;
  min-width: 60% !important;
}
.q-radio-group {
  display: flex;
  flex-direction: row !important;
}
.q-radio-input {
  &.Mui-checked {
    color: #a72037 !important;
  }
}
.MuiFormControlLabel {
  font-family: 'Ambit' !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #000000 !important;
}
.s-btn-block {
  display: flex;
  align-items: center;
  justify-content: center;
  .sub-btn {
    background: #a72037 !important;
    border-radius: 3px;
    font-family: 'Ambit' !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 14px !important;
    color: #ffffff !important;
    text-transform: none !important;
    margin-top: 20px !important;
    padding: 8px 20px !important;
    text-align: center !important;
  }
}
.err-msg {
  margin-top: 0 !important;
}
