.reactQuill {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border: 1px solid rgb(132, 132, 132);
  border-radius: 0.3125rem;
}

/* Style the entire container */
.ql-container {
  border: none;
  border-radius: 0 0 0.3125rem 0.3125rem;
  max-height: 83%; /* Adjust the height of the editor */
}

/* Style the toolbar */
.ql-toolbar {
  background-color: #f4f4f4;
  border: none;
  border-bottom: 1px solid rgb(132, 132, 132) !important;
  padding: 10px;
  border-radius: 0.3125rem 0.3125rem 0 0;
}

/* Style the toolbar buttons */
.ql-toolbar button {
  display: flex !important;
  background-color: transparent;
  justify-content: center;
  border: none;
  margin-right: 5px;
  padding: 15px !important;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.ql-toolbar button:hover {
  background-color: #e0e0e0;
}

/* Customize specific icons */
// .ql-bold::after {
//   content: 'B';
//   font-weight: bold;
//   font-size: 16px;
// }

// .ql-italic::before {
//   content: 'I';
//   font-style: italic;
//   font-size: 16px;
// }

.ql-editor {
  padding: 15px;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
}

.ql-toolbar button:hover {
  background-color: #ddd;
}

.ql-toolbar .ql-active {
  background-color: #aaa;
  color: white;
}

.pagination .MuiInputBase-root-MuiTablePagination-select {
  width: 5.3125rem !important;
  border-radius: 0.3125rem !important;
  background-color: white !important;
  border: 1px solid var(--Secondary-colors-Grey, #868686) !important;
  .MuiNativeSelect-select-MuiInputBase-input.MuiNativeSelect-select-MuiInputBase-input.MuiNativeSelect-select-MuiInputBase-input {
    width: 38px !important;
    padding-right: 52px !important;
  }
  .MuiNativeSelect-icon {
    margin-right: 0.81rem !important;
    top: calc(50% - 0.4em) !important;
  }
}

.job-details-paper {
  width: 42rem;
  height: 79.9325rem;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  background-color: white;
  border: 1px solid var(--Secondary-colors-Grey, #868686);
}

.job-details-paper {
  width: 42rem;
  height: 79.9325rem;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  border: 1px solid #c1c0c0;
  background: var(--White-Color, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.header-container {
  background: var(--Secondary-Grey-2, #e7e7e7);
  border-bottom: 1px solid #c1c0c0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-left {
    display: flex;
    color: var(--Primary-Dark-grey-500, #333);
    font-family: 'Roboto', sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 1.19rem;
  }

  &-right {
    float: right;
    margin-right: 1.06rem;
  }
}

.divider {
  color: #c1c0c0;
  margin-block-end: 0em;
  margin-block-start: 0em;
  width: 39.5rem;
}

.main-container {
  height: 23.5rem;
}

.main-container1 {
  height: 8rem;
}

.main-container2 {
  height: 12rem;
}

.main-container10 {
  height: 12rem;
}

.main-container3 {
  height: 6rem;
}

// .label-value-container {
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   gap: 10rem;
//   margin-top: 1rem;
// }

// .label {
//   min-width: 5rem;
// }

.text {
  color: var(--Primary-Dark-grey-500, #333);

  /* Body Semi Bold */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5625rem;
  width: 12.3rem;
}

.texts {
  color: var(--Primary-Dark-grey-500, #333);
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 1200;
  line-height: 1.5625rem;
}

.label-value-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7rem;
  margin-top: 1rem;
}

.label {
  width: 11.125rem;
  height: 1.5625rem;
}

.box {
  width: 6.25rem;
  height: 6.25rem;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  border: 1px solid var(--Secondary-Grey-2, #e7e7e7);
  background: var(--Secondary-colors-Grey-3, #f1f4f8);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-boards-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  height: 80%;
  align-content: flex-start;
  overflow-y: auto;
  margin-bottom: 10px;
}

.text-chat-box {
  padding-left: '2rem';
  margin-bottom: 1.17rem;
  display: 'flex';
  flex-direction: 'column';
  // @media (max-width: 1537px) {
  //   max-height: 25rem;
  // }
}

.status-dropdown .MuiSelect-select {
  padding-right: 0.5rem !important;
}
.status-box {
  font-size: medium;
  vertical-align: 2.4px;
  text-align: center;
  padding-left: 0.5rem !important;
}

.link-div {
  text-decoration: underline;
  color: #1976d2;
  cursor: pointer;
}
