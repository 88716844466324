.top-navbar {
  position: absolute;
  height: 50px;
  left: 0px;
  top: 0px;

  background: #a72037 !important;
}

.top-navbar .icon {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 9.52px;
  top: 9.52px;
  background: #ffffff;
  border-radius: 10%;
}

.top-navbar .user-icon {
  position: absolute;
  cursor: pointer;
  width: 35px;
  height: 35px;
  right: 25px;
  top: 5px;
}

.top-navbar .user-name {
  position: absolute;
  width: fit-content;
  right: 70px;
  top: 10px;
  font-family: 'Ambit';
  font-style: normal;
  font-size: 15px;
  line-height: 1.3vw;
  color: #ffffff;
}

.top-navbar .role {
  position: absolute;
  width: 63px;
  height: 1vw;
  right: 63px;
  top: 30px;
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1vw;
  color: #ffffff;
}
.page-tabs {
  display: flex;
  justify-content: center;
  background: #565353;
  margin-top: 2px;
  min-height: 30px;
}

.page-tabs button {
  color: white !important;
  min-height: 30px !important;
  line-height: 0.4 !important;
  padding: 10px 12px !important;
  font-size: 13px;
}
.page-tabs button.Mui-selected {
  color: #a72037 !important ;
  background: white;
}

.page-tabs div.MuiTabs-root {
  min-height: 30px !important;
}
