.workflow-container {
  padding: 1.88rem;
  margin: 0 auto;
}

.stage-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px !important;
  padding: 10px !important;
  border-radius: 0.625rem;
  border: 1px solid #c1c0c0;
  background: var(--Secondary-colors-White, #fff);
  height: 3.875rem;
}

.drag-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin: 1.19rem;
}

.stage-actions button {
  margin-left: 5px;
  padding: 5px 10px;
}

.stage-content {
  display: flex;
  align-items: center;
}

.stage-content-rectangle {
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.3125rem;
  margin: 1.19rem;
  background: var(--custom-accent, #82b1ff);
}

.stage-content-text {
  color: var(--Primary-Dark-grey-500, #333);
  font-family: Roboto;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5625rem; /* 125% */
}

.lock-icon {
  margin-left: 10px;
  color: red;
}

.locked path {
  fill: #e7e7e7; /* Locked color */
}

.unlocked path {
  fill: currentColor; /* Unlocked color */
}

.arrowLeft {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
}

.arrowRight {
  width: 1.5rem;
  height: 1.5rem;
  transform: rotate(180deg);
  flex-shrink: 0;
}

.dropdown-list {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.dropdown-list-container {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.dropdown-list-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px !important;
  padding: 10px !important;
  border-radius: 0.625rem;
  border: 1px solid #c1c0c0;
  background: var(--Secondary-colors-White, #fff);
  width: 100%;
}

.item-actions {
  display: flex;
  justify-content: space-between;
}

.item-actions button {
  margin-left: 5px;
  padding: 5px;
}

.list-field {
  margin: 10px !important;
  display: flex;
  gap: 1rem;
}

.list-field .MuiOutlinedInput-root {
  border-radius: 0.625rem !important;
}

.add-icon path {
  fill: #1976d2;
}

.add-item {
  display: flex;
  gap: 10px;
}

.add-item input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.add-item button {
  padding: 5px 10px;
}

.doneIcon {
  width: 1.2rem;
  height: 1.2rem;
}

.doneIcon path {
  fill: #2d7dfe;
}
