.header-name {
  font-size: 22px !important;
  font-weight: 700 !important;
}

.search-box {
  padding: 30px;
  margin-top: 30px;
  .search-form {
    display: flex;
    gap: 1.14vw;
    width: 70%;
  }
  .exp-field {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .btn-field {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    width: 30%;
    margin-bottom: 5px;
  }
  .error-msg {
    padding-top: 10px;
    color: red;
  }
}
