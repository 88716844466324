.dashboard {
  display: flex;
  .left-section {
    width: 25%;
  }
  .right-section {
    width: 25%;
  }
  .middle-section {
    width: 50%;
    margin: 0 10px;
  }

  .qc-table {
    overflow: scroll;
    max-height: 40vh;
  }
  .qc-table::-webkit-scrollbar {
    width: 8px;
  }

  .qc-table::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  .qc-table::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  .qc-table::-webkit-scrollbar-thumb:horizontal {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  .active-interviews {
    max-height: 50vh;
    overflow-y: scroll;
  }

  .active-interviews::-webkit-scrollbar {
    width: 8px;
  }
  .active-interviews::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .active-interviews:hover {
    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
  }
  .interview-info {
    background-color: #f9f9f9;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    > div {
      display: flex;
      margin-bottom: 5px;
      > div {
        width: 50%;
        > span:nth-of-type(1) {
          font-size: 12px;
          font-weight: 400;
        }
        > span:nth-of-type(2) {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }
}

.custom-tooltip {
  background-color: #ffffff;
  padding: 0;
  border: none;
  > p {
    margin: 0px 5px;
    border: none;
  }
}

.source-channel-tooltip {
  background-color: #f5f5f5;
  padding: 5px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  > p {
    margin: 0px 5px;
    border: none;
    font-weight: 600;
  }
  > div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 5px;
  }
}

.container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 300px;
  background-color: #fff;
}

.audit-period-select {
  border: none;
  cursor: pointer;
  &:focus-visible {
    outline: none !important;
  }
}
.date-picker-funnel input {
  padding: 2px 1px;
  display: flex;
  width: 79px;
  justify-content: space-between;
  font-size: small;
}

.audit-select {
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;
  &:focus-visible {
    outline: none !important;
  }
}

.rating-trend-graph {
  margin-left: -20%;
  width: 125%;
  height: 250px;
}

.chart-container {
  width: 60%;
}
