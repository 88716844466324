.app-bar {
  display: flex;
  align-items: center;
  background: var(--Primary-Blue-500, #1976d2);
  height: 2.3rem;
  justify-content: space-between;
  position: sticky;
  z-index: 100;
  background-image: url('../../../assets/dashboard-assets/Mask group.png');
  left: 0;
  top: 0;
  background-position: right center;
  background-repeat: no-repeat;
  padding: 0.75rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.left-div {
  display: flex;
}

.right-div {
  display: flex;
  align-items: center;
}

.new-dashboard-menu {
  min-height: 70px;
  top: 61px;
  left: 0;
  display: flex;
  background-color: #c7c6c6;
  background: var(--Secondary-colors-White, #fff);
  justify-content: space-between;
  z-index: 100;
  position: sticky;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;
}

.img {
  width: 1.24913rem;
  height: 1.23763rem;
  flex-shrink: 0;
  fill: var(--White-Color, #fff);
}

.right-header {
  flex-grow: 1;
  justify-content: flex-end;
}

.p {
  color: white;
}

.avatars .MuiAvatar-root-MuiAvatarGroup-avatar {
  height: 30px !important;
  width: 30px !important;
}

.drawer .MuiDivider-root {
  display: none !important;
}

.menu-list .MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  display: flex !important;
  width: 15.625rem !important;
  height: 13.9375rem !important;
  flex-shrink: 0 !important;
  border: 1px solid #c1c0c0 !important;
}
.menu-list .MuiList-root-MuiMenu-list {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-evenly !important;
}

.app-list .MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
  width: 25.0625rem !important;
  height: 21.75rem !important;
  flex-shrink: 0 !important;
  border: 1px solid #c1c0c0 !important;
  padding-left: 1.44rem !important;
  padding-top: 1.88rem !important;
  padding-right: 1.69rem !important;
  gap: 1.75rem !important;
}

.app-list .MuiList-root-MuiMenu-list {
  display: flex !important;
  flex-direction: column !important;
  padding: 0 !important;
  width: 100% !important;
}

.menuItem {
  color: var(--Primary-Dark-grey-500, #333);
  font-family: var(--Font, Roboto);
  font-size: var(--Font-size-P, 1rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
