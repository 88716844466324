.MuiTabs-indicator {
  background-color: #1976d2 !important;
}
.custom-text {
  color: #1976d2 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.5625rem !important;
  text-decoration-line: underline !important;
}
.user-text {
  color: var(--Primary-Dark-grey-500, #333) !important;
  font-family: var(--Font, Roboto) !important;
  font-size: var(--Font-size-P, 1rem) !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
