.slider {
    background-color: #909090;
    width: 100%;
    height: 0px;
    border-radius: 5px;
    display: flex;
    flex-flow: column;
    padding-bottom: 10px;
  }

  .vertical-slider {
    background-color: #909090;
    width: 100%;
    height: 0px;
    border-radius: 5px;
    display: flex;
    padding-bottom: 10px;
  }
  
  .progress-bar {
    display: flex;
    align-items: center;
  }
  .completed {
    width: 40%;
    height: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  
  .pending {
    width: 60%;
    height: 5px;
    background-color: #D9D9D9;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .icon-div {
    display: flex;
    align-items: center;
    height: 5px;
    margin-top: 3px;
  }
  
  .icon {
    color: #a72037;
  }
  