.candidate-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: var(--Secondary-colors-Grey-3);
}

.candidate-content {
  display: flex;
  gap: 1.5rem;
  width: 100%;
}

.candidate-content-right {
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.candidate-content-left {
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.candidate-tab {
  font-weight: 700 !important;
  font-size: 13px !important;
  color: #4e4f50 !important;
  text-transform: capitalize !important;
}

.candidate-tab.Mui-selected {
  color: #1976d2 !important;
}

.candidate-tabs .MuiTabs-indicator {
  background-color: #1976d2 !important;
}
