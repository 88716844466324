.grid-btn {
  background-color: #a61f36 !important;
  border-color: #a61f36;
  height: 40px;
  min-width: 64px;
  padding: 0 16px !important;
  color: #fff !important;
  caret-color: #fff !important;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.grid-btn:disabled {
  opacity: 0.5;
}

.truncate-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-transform: capitalize;
}
.report-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  h1 {
    font-weight: 700 !important;
    font-size: medium;
    font-family: 'Ambit' !important;
    color: #000000 !important;
  }
  .export-search-block {
    display: flex;
    align-items: center;
    .search-bar-report {
      margin-top: 0 !important;
      height: 35px;
      margin-bottom: 0;
    }
    .export-btn {
      margin-left: 10px;
      height: auto !important;
      padding: 5px 30px !important;
      text-transform: capitalize !important;
    }
  }
}

.loader-button {
  background-color: #a61f36 !important;
  border-radius: 4px;
  color: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  min-width: 140px;
  margin-top: 20px;
}

.report-field {
  margin-right: 30px;
}
