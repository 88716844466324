.candidate {
  margin-top: 10px;
  .row {
    display: flex;
    margin-top: 35px;
  }

  .row:last-child {
    margin-bottom: 35px;
  }

  .field {
    width: 25%;
    padding: 0px 20px;
  }
}

.center-contents {
  display: flex;
  justify-content: center;
}

.candidate-data {
  width: 100%;
  border-spacing: 0px;
  margin-bottom: 30px;
  tbody {
    border-spacing: 0px;
    color: #565555de;
    tr {
      height: 65px;
      font-weight: 400;
      background-color: #f1fafb;
      td {
        padding: 0px 10px;
        border-bottom: 3px solid #ffffff;
      }
    }
    tr:first-child {
      font-weight: 700;
      background-color: #f5f2f2;
      td {
        border-bottom: 3px solid #efc2ca;
      }
    }
  }
}

.status-div {
  display: flex;
  justify-content: center;
  padding: 5px 20px 20px 20px;
}

.status-bar {
  width: 73%;
  display: flex;
  align-items: center;
}

.status-button {
  display: flex;
  justify-content: flex-end;
  // align-items: center;
  width: 27%;
  min-width: 382px;
}

.arrow-btn {
  background: #a72037 !important;
  color: #ffffff !important;
  font-family: 'Ambit' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px;
  text-transform: none !important;
  border: 1px solid #a72037 !important;
  border-radius: 5px;
  float: right;
  padding: 0px !important;
}

.MuiYearCalendar-root {
  height: 180px;
}

.MuiMonthCalendar-root {
  height: 150px;
}

.MuiPickersMonth-root {
  flex-basis: 24% !important;
}

.status-popper {
  height: 200px;
  overflow-y: scroll;
  box-shadow: 2px 2px 5px 1px #909090;
}

.rating-div {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding-left: 5px;
  font-weight: 500;
}

.MuiRating-root {
  color: #b9032c !important;
}

.comment:first-child {
  border-top: 1px solid #909090;
}
.comment {
  border-bottom: 1px solid #909090;
  padding-top: 15px;
}

.comment > p {
  font-weight: 300;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 5px;
  span {
    font-weight: 500;
  }
}

.comment-btn {
  width: 20% !important;
  padding: 5px !important;
}
.resume-preview-block {
  max-height: 70vh;
  margin-top: 10px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.resume-preview-block::-webkit-scrollbar {
  display: none;
}

.file-field input {
  padding: 6px 8px;
}

.feedback {
  background-color: #f9f9f9;
  padding: 5px 0px 5px 7px;
  border-right: 6px solid #ffffff;
  p {
    margin: 0;
  }
  p:first-of-type {
    font-weight: 500;
    margin-bottom: 3px;
  }
}

.feedback-table {
  border-spacing: 0px;
}

.bg-btn:disabled,
.bg-btn[disabled],
.default-btn:disabled,
.default-btn[disabled] {
  opacity: 60%;
}

.interview-field {
  padding: 0px 10px;
}

.w-10 {
  width: 16%;
}

.w-30 {
  width: 28%;
}

.w-60 {
  width: 60%;
}

.feedback-msg {
  display: block;
  padding: 10px 0px;
  border-bottom: 1px solid #ebebeb;
}
.input-block {
  margin-bottom: 10px;
}

.search-modal {
  background-color: #f5f5f5;
  // width: 80vw;
  // height: 50vh;
  padding: 10px;
  margin: auto 10px;
}

.search-dialog > .MuiDialog-container > .MuiPaper-root {
  background-color: transparent;
}

.view-pdf {
  height: 65vh;
  margin-top: 10px !important;
}

.move-to-top {
  margin-bottom: 3px;
  position: fixed !important;
  right: 10px;
  bottom: 10px;
  display: flex;
  justify-content: flex-end;
  .circle {
    // padding: 5px;
    width: 35px;
    height: 32px;
    padding-top: 2px;
    border-radius: 50%;
    background-color: #a72037;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      color: #ffffff !important;
      text-decoration: none;
      font-size: 25px;
    }
  }
}

.fail {
  color: #e11f1f !important;
  + span {
    color: #e11f1f !important;
    font-weight: 600 !important;
  }
}

.pass {
  color: #008000 !important;
  + span {
    color: #008000 !important;
    font-weight: 600 !important;
  }
}

.current-ctc-input,
.expected-ctc-input {
  > div {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.current-ctc-select,
.expected-ctc-select {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.textarea-field {
  font-size: 0.875rem;
  font-weight: 400;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: #24292f;
  background: '#fff';
  border: 1px solid #d0d7de;
  box-shadow: 0px 2px 2px #f6f8fa;
}

.textarea-field:hover {
  border-color: #3399ff;
}

.textarea-field:focus {
  border-color: #3399ff;
  box-shadow: #b6daff;
}
.resume-preview {
  height: 150vh;
  overflow-y: hidden;
  margin-top: 5px;
}

.rpv-core__inner-page {
  height: auto !important;
  padding-bottom: 150px !important;
}

.pdf-viewer .rpv-core__inner-pages {
  overflow: hidden !important;
}

.tad-date-picker input{
  padding: 8.5px 14px !important;
}

.tad-date-picker .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color : none !important;
}


