.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--Secondary-Grey-2, #e7e7e7);
  height: 60px;
}

.header-content-title {
  display: flex;
  color: var(--Primary-Dark-grey-500, #333);
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 1.19rem;
}

.header-content-icon {
  margin-right: 10px;
}

.field-container {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: flex-start;
}

.custom-popper {
  z-index: 1 !important;
  border: 1px solid #e5e5e5;
}

.input-field .MuiInputBase-root {
  height: 2.5rem !important;
  display: flex;
  align-items: center;
  // padding: 0 !important;
}

.input-field .MuiInputBase-input {
  padding: 0.5rem !important;
  height: 1.5rem !important;
  box-sizing: border-box;
}

.input-field .MuiOutlinedInput-notchedOutline {
  // border: 1px solid var(--Secondary-colors-Grey, #868686) !important;
  border-radius: 0.3125rem;
}

.input-field .MuiOutlinedInput-input.Mui-disabled {
  cursor: not-allowed !important;
  opacity: 0.6; /* Reduced opacity for disabled state */
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
}

.input-field .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline,
.input-field.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #dc3857 !important; /* Red border for error state */
}

.input-field .MuiOutlinedInput-input:-webkit-autofill {
  background-color: #ffffff !important; /* Same as the input field */
  transition: background-color 5000s ease-in-out 0s;
}

.input-field .MuiSelect-select {
  min-height: 2.5rem !important;
  display: flex;
  align-items: center;
  padding: 0 0.75rem !important;
  background-color: #fff;
}

.input-field .MuiMenuItem-root {
  padding: 0.5rem 1rem !important; /* Standardize menu item padding */
}

.input-field .MuiCheckbox-root {
  padding: 0.5rem !important;
}

.input-field .MuiListItemText-root {
  margin: 0 0.5rem;
}

.text-label {
  color: var(--Primary-Dark-grey-500, #333);
  /* Body */
  font-family: Roboto;
  // font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5625rem; /* 156.25% */
}

.header-label {
  color: var(--Primary-Dark-grey-500, #333);
  font-family: Roboto;
  // font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.copy-label {
  color: var(--Primary-Dark-grey-500, #333);
  /* Body */
  font-family: Roboto;
  // font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5625rem; /* 156.25% */
}

.copy {
  visibility: hidden;
}

.copy-container:hover .copy {
  visibility: visible;
}

.role-text-label {
  color: #000;
  /* Body */
  font-family: Roboto;
  // font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5625rem; /* 156.25% */
}

.question-type {
  display: flex;
  gap: 0.5rem;
  padding: 0.8rem;
  width: 20%;
  cursor: pointer;
  border-radius: 0.3125rem;
  border: 2px solid #000;
}

.question-type:hover {
  border: 2px solid #1976d2;
}

.choice-radio {
  color: #323232 !important;
  + span {
    color: #323232 !important;
    font-weight: 400 !important;
  }
}
