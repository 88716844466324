.sjd-info-block {
  border-radius: 7px;
  font-size: 14px;
  position: sticky;
  top: 52px;
  z-index: 999;
}

.sjd-info-table,
.candidate-info-table {
  width: 100%;
  border-spacing: 0px;
  border: 1px solid #ededed;
  padding: 6px 0px;
}

.candidate-info-table {
  margin-top: 20px;
  td {
    vertical-align: top;
  }
}

.sjd-info-table {
  tbody {
    tr {
      td {
        border-right: 1px solid #ededed;
        padding-left: 10px;
        p {
          margin: 5px;
        }
      }
      &:first-child {
        td {
          font-weight: 700;
        }
      }
    }
  }
}

.sortLabel {
  text-align: center;
  vertical-align: 2px;
  margin-left: 5px;
  font-size: small;
}

.candidate-info-table {
  tbody {
    tr {
      td {
        padding-left: 10px;
        p {
          margin: 5px;
        }
      }
    }
    tr:not(:last-child) {
      td {
        border-bottom: 5px solid #ededed;
      }
    }
  }
}

.candidate-list-icon {
  vertical-align: -2.7px;
  font-size: 20px !important;
  height: 14px !important;
  color: #a72037;
}

.candidate-list-key {
  font-size: 12px;
  font-weight: 600;
}

.candidate-list-value {
  font-size: 12px;
  font-weight: 500;
}
.status {
  font-size: small;
  vertical-align: 2.4px;
  text-align: center;
}
.filter-Alt-Icon {
  vertical-align: -2.7px;
}
.side-menu {
  width: 12%;
  p {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-right: 5px;
    cursor: pointer;
    width: fit-content;
    padding-top: 5px;
    border-top: 1px solid #000000;
    transition: font-size 0.5s;
    > a {
      text-decoration: none;
      color: #000000;
    }
  }
  p:hover {
    font-size: 15px;
    > a {
      text-decoration: none;
      color: #000000 !important;
    }
  }
}

.selected {
  font-weight: 700 !important;
  border-top: 1px solid #a72037 !important;
  > a {
    color: #a72037 !important;
  }
}

.details {
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
}

.details::-webkit-scrollbar {
  display: none;
}

.details-section {
  /* min-height: 50vh; */
  padding-left: 20px;
  border: 1px solid #ededed;
  background-color: #ffffff;
  margin-top: 20px;
  border-radius: 5px;
}

.details-section span.heading {
  margin: 0px;
  font-size: 18px;
  font-weight: 700;
}

.details > .MuiPaper-root {
  background-color: #f5f5f5 !important;
}

.row {
  display: flex;
}

/* .field {
  width: 25%;
  padding: 0px 20px;
} */

.row .field {
  width: 33%;
  padding: 0px 20px;
}

.fields-row {
  margin-top: 35px;
}

.field-by-4 {
  width: 25%;
  padding: 0px 20px;
  margin-top: 35px;
}

.delete-icon {
  cursor: pointer;
  color: #a72037;
}

.delete-icon-disabled {
  color: #e4bac1;
}

.candidate-actions > div {
  margin-right: 10px;
}

.MuiListItemText-primary {
  font-size: 16px;
}
