.questionnaire-form {
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
}

.questionnaire-heading {
  color: #333 !important;
  font-family: Roboto;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.5625rem !important;
}

.questionnaire-type-section {
  .form-label {
    color: #333 !important;
    font-family: 'Roboto' !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5625rem !important;
  }
}

.questionnaire-buttons-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  .form-label {
    color: #333 !important;
    font-family: 'Roboto' !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5625rem !important;
  }
}

.questionnaire-button {
  text-transform: none !important;
  color: #fff !important;
  font-family: 'Roboto' !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  padding: 0 !important;
  width: 7.5rem !important;
  height: 2.5rem !important;
  border-radius: 0.3125rem !important;
  // background-color: var(--Primary-Blue-500, #1976d2) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 0.7rem !important;
}

.questionnaire-tabs {
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--Secondary-colors-Grey-2, #e6e7e8) !important;
}

.questionnaire-tabs .MuiTabs-indicator {
  background-color: var(--Primary-Blue-500, #1976d2) !important;
  text-transform: none;
}

.questionnaire-content {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}

.general-questionnarie-container {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  align-items: center;
}

.questionnaire-text {
  font-size: 1rem;
  font-weight: 500;
}

.questionnaire-delete-Icon {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.technical-questionnaire-container {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}

.techincal-questionnaire-option {
  padding: 1rem;
  background: var(--Secondary-colors-Grey-3, #f1f4f8);
  border-radius: 5px;
  border: 1px solid var(--Secondary-colors-Grey, #868686);
}

.create-questionnaire-container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  border-radius: 0.3125rem;
  border: 1px solid var(--Secondary-colors-Grey-2, #e6e7e8);
  background: var(--Secondary-colors-Grey-3, #f1f4f8);
  padding: 1.3rem;
  margin-bottom: 1rem;
}

.questions-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-bottom: 1px solid var(--Secondary-colors-Grey, #868686);
  padding-bottom: 0.5rem;
}

.questions-left-action-section {
  display: flex;
  width: 85%;
  border-right: 1px solid black;
  justify-content: flex-end;
  padding-right: 1rem;
}

.questions-right-action-section {
  width: 15%;
  display: flex;
  padding-left: 1rem;
}

.add-question-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
}
