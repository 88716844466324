table {
  width: 100%;
  font-size: 14px;
  border: none;
}

.content-row > td:nth-child(odd) {
  width: 49%;
  padding: 0 20px;
  font-weight: 600;
}

.content-row > td:nth-child(odd) > p {
  margin: 0;
}

.content-row > td:nth-child(1) {
  text-align: right;
}

.content-row > td:nth-child(2) {
  width: 2%;
  text-align: center;
}

.connector-row > td:nth-child(2) {
  text-align: center;
}
.connector-row > td:nth-child(2) > div {
  height: 25px;
  width: 2px;
  display: inline-block;
  background-color: #000000;
}

.icon-element {
  background-color: #fff1f4;
  color: #a72037;
  border-radius: 50%;
  padding: 5px;
}

.connector-row:last-child {
  display: none;
}
