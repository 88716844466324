.more {
  color: var(--Hyperlink, var(--Primary-Blue-500, #1976d2));
  text-decoration: underline;
  font-weight: 400;
  margin-left: 60px;
}

.class-icon {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .path {
    fill: grey;
  }
}

.table-text {
  color: var(--Body-P, var(--Primary-Dark-grey-500, #333));
  /* Body */
  font-family: Roboto;
  // font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5625rem; /* 156.25% */
}

.cell {
  padding: 12px !important;
  vertical-align: baseline !important;
}
