.custom-typography {
  color: var(--Primary-Dark-grey-500, #333) !important;
  font-family: 'Roboto' !important;
  font-size: var(--Font-size-P, 1rem) !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 1.5rem !important;
  padding-top: 15px !important;
}

.itsupport-email {
  color: var(--Primary-Blue-500, #1976d2) !important;
  font-family: Roboto, sans-serif !important;
  font-size: var(--Font-size-P, 1rem) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
  text-decoration-line: underline !important;
}
