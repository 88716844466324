.upload{
 margin-top:1.25rem;
    height: 13.75rem;
flex-shrink: 0;
border-radius: 0.3125rem;
border: 1px solid var(--Secondary-colors-Grey, #868686);
background: var(--Secondary-colors-Grey-3, #F1F4F8);
display: flex;
align-items: center;
}

.text{
    width: 61.8125rem;
    color: var(--Primary-Dark-grey-500, #333);
font-family: Roboto;
font-size: var(--Font-size-P, 1rem);
font-style: normal;
font-weight: 400;
line-height: 1.5625rem; 
}

.table{
    margin-Top:'1rem'
}

// addcandidate.scss

.text-status {
    color: var(--Primary-Dark-grey-500, #333);
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5625rem; // 156.25%
    margin-left: 23.25rem; // Add margin for spacing
  
  }
  
  // Apply this class to the status Typography if you prefer using SCSS classes
  
