
:root {
    --Primary-Blue-500: #1976d2;
    --Primary-Blue-400: #4791db;
    --Primary-Blue-300: #65a3e1;
    --Primary-Blue-200: #95c0ea;
    --Primary-Blue-100: #b8d5f1;
    --Primary-Dark-grey-500: #333;
    --Primary-Dark-grey-400: #5c5c5c;
    --Primary-Dark-grey-300: #767676;
    --Primary-Dark-grey-200: #a1a1a1;
    --Primary-Dark-grey-100: #c0c0c0;
    --White-color: #ffffff;
    --Green-color-500: #4bb543;
    --Green-color-400: #6fc469;
    --Green-color-300: #86cd81;
    --Green-color-200: #acdda9;
    --Green-color-100: #c7e8c5;
    --Yellow-color-500: #fbae19;
    --Yellow-color-400: #fcbe47;
    --Yellow-color-300: #fcc965;
    --Yellow-color-200: #fdda95;
    --Yellow-color-100: #fee6b8;
    --Oranage-color-500: #f26c20;
    --Oranage-color-400: #f5894d;
    --Oranage-color-300: #f69d6a;
    --Oranage-color-200: #f9bb98;
    --Oranage-color-100: #fbd1ba;
    --Red-color-500: #dc3857;
    --Red-color-400: #e36079;
    --Red-color-300: #e87a8e;
    --Red-color-200: #efa3b2;
    --Red-color-100: #f4c1cb;
    --Secondary-colors-White: #ffffff;
    --Secondary-colors-Grey: #868686;
    --Secondary-colors-Grey-2: #e6e7e8;
    --Secondary-colors-Grey-3: #f1f4f8;
  
    --Font-family: 'Roboto';
    --Font-size-h1: 48px;
    --Font-size-h2: 40px;
    --Font-size-h3: 33px;
    --Font-size-h4: 28px;
    --Font-size-h5: 23px;
    --Font-size-h6: 19px;
    --Font-size-p: 16px;
    --Font-size-small: 13px;
  
    --Font-Weight-Bold: Bold;
    --Font-Weight-Semi-Bold: Semi Bold;
  }
  