.user-form {
  margin: 10px !important;
}

.operation-select-field {
  width: 180px !important;
}

.user-profile-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin-left: 50px;
  text-align: center;
  background: white;
}

.user-profile-title {
  color: grey;
  font-size: 18px;
}

.user-profile-card button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #a72037;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

.user-profile a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

.user-profile-card button:hover,
a:hover {
  opacity: 0.7;
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2px; 
}

.chip-container > div {
  flex: 1 1 30%;
}

