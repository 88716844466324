
.semi-bold {
    color: var(--Primary-Dark-grey-500, #333);
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5625rem; 
}

.text-font {
    color: var(--Primary-Dark-grey-500, #333);
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5625rem;
}

.rating {
  border-radius: 5px;
  padding-left: 5px;
  font-weight: 500;
}
.box-bg {
  height: 3rem;
  padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F1F4F8;
    border-radius: 4px; 
    flex-grow: 1;
    margin-top: 10px;
    margin-bottom: 10px; 
}

  .add-icon path {
    fill: #1976d2;
  }

.filled-star {
  color: #FFD700
}


.empty-star {
  color: #BDBDBD;  
  opacity: 0.55;
}

.star-icon {
  height: 3.5rem;
  padding: 0rem var(--Font-size-P, 1rem);
}

.rating .star-icon:first-child {
  padding-left: 0;
}

  
  