@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';
@import '@fontsource/open-sans/300.css';
@import '@fontsource/open-sans/400.css';
@import '@fontsource/open-sans/500.css';
@import '@fontsource/open-sans/600.css';
@import '@fontsource/open-sans/700.css';

@font-face {
  font-family: 'Ambit';
  src: url('assets/fonts/ambit/Ambit-Bold.ttf') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Ambit';
  src: url('assets/fonts/ambit/Ambit-Light.ttf') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Ambit';
  src: url('assets/fonts/ambit/Ambit-Regular.ttf') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Ambit';
  src: url('assets/fonts/ambit/Ambit-SemiBold.ttf') format('woff');
  font-weight: 600;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #f5f5f5;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Ambit', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, 'Segoe UI';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.new-login {
  background-image: url(assets/login-bg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  width: 100%;
}

.logo-block {
  background-color: transparent;
  width: 36px;
  height: 36px;
  margin-left: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.logo-block h1 {
  color: #a72037;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  margin: 0;
  line-height: 20px;
}

.form-block {
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
  margin-left: 30px;
}

.welcome-text h2 {
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  color: #ffffff;
  color: #ffffff;
  margin-bottom: 30px;
}

.new-form-field {
  background: rgba(241, 241, 241, 0.41);
  border: none;
  backdrop-filter: blur(5px);
  border-radius: 3px;
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  width: 450px;
  margin-bottom: 20px !important;
}

.new-form-field input {
  padding: 12px 12px !important;
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 23px;
  color: #ffffff;
}

.input-label {
  color: #ffffff !important;
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 600;
  font-size: 15px !important;
  line-height: 28px;
  margin-bottom: 10px !important;
}

.microsoft-login-text {
  padding-right: 10%;
  h3 {
    margin: 0;
    font-family: 'Ambit';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #ffffff;
  }
  p {
    font-family: 'Ambit';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin: 0;
  }
}

.right-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  margin-left: 80px;
  padding-top: 80px;
}

.right-text h3 {
  margin: 0;
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #ffffff;
}

.right-text p {
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  margin: 0;
}

.btn-block button {
  background: #a72037;
  border-radius: 3px;
  width: 450px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
  gap: 8px;
  color: #ffffff;
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 28px;
  text-transform: capitalize;
  margin-top: 10px;
  margin-bottom: 5px;
}

.btn-block button:hover {
  background: #a72037 !important;
}

.forgot-link {
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  color: #ffffff;
  margin-top: 5px;
  text-align: end;
}

.err-msg {
  margin-top: -15px;
  color: #ff0000;
  margin-bottom: 10px;
  font-size: 16px;
}
.m-auto {
  margin: 0 auto;
}
.th-cell {
  padding-left: 0 !important;
  cursor: pointer;
  position: relative;
  font-family: 'Ambit' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 16px;
  color: #1e1e1e !important;
}
.td-cell {
  color: #494949 !important;
  font-family: 'Ambit' !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
}
.th-cell::after {
  content: '';
  position: absolute;
  left: 0px;
  top: 25%;
  height: 50%;
  border-left: 1px solid #cecece;
}
.th-cell:first-child::after {
  border-left: 0px;
}
.hyper-link {
  color: #3366cc;
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none !important;
}
.pagination p {
  color: #000000 !important;
  font-family: 'Ambit' !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
}
.in-progress {
  color: #9c6700 !important;
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  border: 1px solid #9c6700 !important;
  background: #fef0d6 !important;
  border-radius: 5px;
  text-transform: capitalize !important;
  padding: 4px 6px !important;
}

.menu-list {
  min-width: 170px;
  border-radius: 5px !important;
  color: #000000;
  font-family: 'Ambit' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
}
.search-bar {
  border-radius: 5 !important;
  margin-right: 15px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-top: 20px;
  background: white;
}
.search-bar input {
  padding: 10px;
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.source-block {
  background-color: #f5f5f5;
  padding-left: 10px;
  padding-right: 10px;
}

.default-btn {
  color: #a72037 !important;
  font-family: 'Ambit' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px;
  text-transform: none !important;
  background: #ffffff !important;
  border: 1px solid #a72037 !important;
  border-radius: 5px;
  float: right;
  padding: 6px 20px !important;
}

.success-btn {
  color: #ffffff !important;
  font-family: 'Ambit' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px;
  text-transform: none !important;
  background: #007f39 !important;
  border: 1px solid #007f39 !important;
  border-radius: 5px;
  float: right;
  padding: 6px 20px !important;
}

.add-sjd-btn {
  color: #a72037 !important;

  border-radius: 5px !important;
  border: 1px solid #a72037 !important;
}
.bg-btn {
  background: #a72037 !important;
  color: #ffffff !important;
}
.source-block .btn-block {
  margin-bottom: 20px;
}
.table-header {
  background: #f9f9f9;
  border-radius: 5px 5px 0px 0px;
  text-align: left;
}
.main-block {
  background: #f5f5f5;
}
.stepper-block {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: none !important;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.steps {
  max-width: 800px;
  width: 100%;
}
.step-label {
  font-family: 'Ambit' !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px;
  color: #494949;
}

.step-label-selected {
  font-family: 'Ambit' !important;
  font-style: normal;
  font-weight: 900 !important;
  font-size: 14px !important;
  line-height: 16px;
  color: #a72037;
}

.steps .MuiStepConnector-line {
  border: 2px solid #e9e9e9 !important;
}
.MuiStepLabel-iconContainer {
  width: 27px;
  height: 26px;
  background: #e1e1e1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.MuiStepLabel-iconContainer .Mui-active {
  width: 25px !important;
  height: 25px !important;
}

/* .detail-header h3 {
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
  color: #000000;
} */

.field-label {
  font-family: 'Ambit' !important;
  font-style: normal !important;
  font-weight: 610 !important;
  font-size: 14px !important;
  color: #565555de !important;
}

.field-block {
  margin-top: 20px;
  padding-top: 10px;
}

.bottom-btn-block {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sjd-form .err-msg {
  margin-top: 0 !important;
}
.form-err-msg {
  color: #ff0000;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: 'Ambit';
  margin-top: 5px;
}

.form-err-message {
  color: #dc3857;
  margin-bottom: 10px;
  font-size: 14px;
  margin-top: 5px;
}

.date-picker input {
  padding: 8.5px 14px;
}
.role-select {
  width: 110px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right-color: transparent;
}
.role-select .MuiSelect-select {
  padding: 8px 0 !important;
}
.role-input input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  width: 250px;
  padding: 10px !important;
  color: #494949 !important;
}
.pt-10 {
  padding-top: 30px !important;
}

.delete-icon {
  cursor: pointer;
}

.role-select > div {
  margin-right: 0px;
  margin-left: 15px;
}

#password-field ~ div {
  padding-right: 5px;
}

.custom-button {
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid;
  background-color: #ffffff;
  color: #a72037;
}

.orange-button {
  background-color: #fef0d6;
  color: #9c6700;
}

.blue-button {
  background-color: #e6f5ff;
  color: #3366cc;
}

.green-button {
  background-color: #def8e3;
  color: #0f9f2f;
}

.table-cell {
  font-family: 'Ambit' !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #2e2f2f;
}
.table-head-cell {
  cursor: pointer;
  font-family: 'Ambit' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #494949 !important;
}

.bg-btn:hover {
  box-shadow: 1px 1px 4px #460d17;
  background-color: #981d32 !important;
}

.success-btn:disabled {
  opacity: 0.7;
}

.default-btn:hover,
.success-btn:hover {
  box-shadow: 1px 1px 4px #909090;
}

.tabs button:hover {
  background-color: #981d32 !important;
}

.MuiAutocomplete-root {
  width: auto !important;
}

.global-setting {
  tr > th:last-child,
  tr > td:last-child {
    border-bottom: none;
  }
}

.global-setting {
  tr:last-child > td {
    border-bottom: none;
  }
}

textarea {
  font-family: 'Ambit' !important;
}

.sign-in-div {
  cursor: pointer;
  background-color: transparent;
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  > div {
    background-color: rgba(255, 255, 255, 0.7);
    color: #000000;
    box-sizing: border-box;
    transition: all 0.3s;
  }
  transition: all 0.3s;
}

.sign-in-div:hover {
  transform: translateY(-2px);
  p {
    font-weight: 500;
  }
  > div {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.sign-in-div:active {
  transform: translateY(2px);
}

.drawer .MuiDrawer-paper {
  height: 64rem !important;
  width: 21.0625rem !important;
}

.drawer .MuiDrawer-paper {
  height: 100% !important;
  background-image: url('../src/tad-revamp-ui/assets/dashboard-assets/abstract-plexus-background-with-connected-lines-dots-plexus-geometric-effect-digital-data-visual_230610-1011\ 1.png') !important;
  background-size: cover !important;
}

.drawer {
  border-radius: 0.3125rem !important;
}

.left-block {
  width: 45px;
  height: 100%;
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  border-right: none !important;
}

.right-block {
  width: 45px;
  height: 100%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  border-left: none !important;
}

.middle-block {
  height: 100%;
  display: flex;
  align-items: center;
  border-left: none !important;
  border-right: none !important;
  > p {
    padding-left: 10px;
    font-size: 20px;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 5.5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(51, 51, 51, 0.5);
  border-radius: 0.625rem;
  margin: 1rem 0;
}
