.nav-bar {
  min-height: 25px;
  top: 0;
  left: 0;
  background-color: #a72037;
  display: flex;
  justify-content: space-between;
  position: sticky;
  z-index: 100;
}

.left-div {
  display: flex;
}

.right-div {
  display: flex;
  align-items: center;
}
.icon {
  height: 35px;
  width: 35px;
  padding: 5px;
  border-radius: 5px;
}

.nav-menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: Ambit;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 100px;
  cursor: pointer;
  border-top: 5px solid #a72037;
  border-bottom: 5px solid #a72037;
  transition: border-bottom 0.5s;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  overflow: hidden;
}

.nav-menu-item:hover {
  background-color: #981d32;
  border-color: #981d32;
}

.nav-menu-item-selected {
  border-bottom: 5px solid #ffffff !important;
}

.user-icon {
  background-color: #ffffff;
  color: #a72037;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Ambit;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.user-name {
  color: #ffffff;
  margin-right: 15px;
  text-align: right;
  > p {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  :last-child {
    font-size: 12px;
  }
}

.logo-anchor {
  text-decoration: none;
}

.logo-anchor:hover {
  opacity: 1;
}

.logo {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: 900;
  padding: 0px 10px;
  font-size: 30px;
  margin: 5px 5px;
  cursor: pointer;
  border-radius: 3px;
}

#menu-appbar > .MuiMenu-paper {
  margin-top: 30px;
}
