.employee-referral {
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: calc(33.33% - 20px);
  min-height: 140px;
  margin: 8px;
  position: relative;
}

.card-actions {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 16px;
}

.search-bar {
  background-color: transparent;
  border: none;
}

.MuiSelect-select.MuiSelect-select {
  padding: 5px;
}

.no-outline .MuiOutlinedInput-notchedOutline {
  border: none;
}

.cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-container {
  display: flex;
  align-items: center;
}
