.tad-table {
  width: 100%;
  font-size: 14px;
}

.tad-content-row > td:nth-child(odd) {
  width: 49%;
  padding: 0 20px;
  font-weight: 600;
}

.tad-content-row > td:nth-child(odd) > p {
  margin: 0;
}

.tad-content-row > td:nth-child(1) {
  text-align: right;
}

.tad-content-row > td:nth-child(2) {
  width: 2%;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.tad-connector-row {
  display: flex;
  justify-content: center;
}

.tad-connector-row > td:nth-child(2) {
  text-align: center;
}
.tad-connector-row > td:nth-child(2) > div {
  width: 0.125rem;
  height: 3.3125rem;
  display: flex;
  justify-content: space-between;
  background-color: #333;
}

.tad-icon-element {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e7e7e7;
  color: #323232;
  border-radius: 50%;
  width: 2.4375rem;
  height: 2.4375rem;
}

.tad-connector-row:last-child {
  display: none;
}
